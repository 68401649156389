<template>
  <div>
    <el-card>
      <el-form ref="searchForm" :model="searchForm" :rules="rules" inline size="mini">
        <div>
          <el-form-item
            label="回复状态:"
          >
            <el-radio-group v-model="searchForm.replyStatus" size="small" prop="proStatus">
              <el-radio :label="6">全部</el-radio>
              <el-radio :label="0">待审核</el-radio>
              <el-radio :label="1">审核未通过</el-radio>
              <el-radio :label="2">审核通过</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>
        <div>
          <el-form-item label="捐赠人:">
            <el-input v-model="searchForm.userName" prop="userName" />
          </el-form-item>
          <el-form-item label="求助标题:">
            <el-input v-model="searchForm.relateProjectName" prop="relateProjectName" />
          </el-form-item>
          <el-form-item label="回复时间:" prop="daterange">
            <el-date-picker
              v-model="daterange"
              type="datetimerange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="mini" @click="onSearch">查询</el-button>
            <el-button type="primary" size="mini" @click="resetSearch('searchForm')">重置</el-button>
          </el-form-item>
        </div>
      </el-form>
      <el-table
        v-loading="dataListLoading"
        :data="list"
        :header-cell-style="{background:'#F7F7F7',color:'#1E1E1E'}"
      >
        <el-table-column type="index" width="55" label="序号" />
        <el-table-column label="捐款编号" prop="id" />
        <el-table-column label="捐款人（昵称）" prop="userName" />
        <el-table-column label="捐款金额（元）" prop="money" />
        <el-table-column label="救助人" prop="receiver" />
        <el-table-column label="救助标题" prop="relateProjectName" />
        <el-table-column label="求助编号" prop="relateId" />
        <el-table-column label="回复时间" prop="replyTime" />
        <el-table-column label="回复内容" prop="replyContent" />
        <el-table-column label="回复状态" prop="replyStatusName" />
        <el-table-column label="操作">
          <template slot-scope="{ row }">
            <el-button v-if="row.replyStatus !== 0" type="text" @click="showDetail(row, 'look')">查看审核</el-button>
            <el-button v-if="row.replyStatus === 0" type="text" @click="showDetail(row)">审核</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        :hide-on-single-page="true"
        :current-page="searchForm.current"
        :page-size="searchForm.size"
        :total="total"
        layout="total, prev, pager, next, jumper"
        style="padding:20px;"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </el-card>
    <reply-detail-dialog ref="replyDetailDialog" @updateList="onSearch" />
  </div>
</template>

<script>
import { getDonationRecord } from '../../utils/api'
import ReplyDetailDialog from './components/ReplyDetailDialog.vue'
import moment from 'moment'

export default {
  components: {
    ReplyDetailDialog
  },
  data() {
    return {
      daterange: [],
      searchForm: {
        current: 1,
        size: 8,
        replyStatus: 6,
        userName: '',
        relateProjectName: '',
        id: '',
        replyStartDate: '',
        replyEndDate: ''
      },
      rules: {},
      list: [],
      total: 0,
      pages: 0,
      dataListLoading: false
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    showDetail(row, type) {
      this.$refs.replyDetailDialog.open(row, type)
    },
    handleSizeChange(val) {
      this.searchForm.size = val
      this.searchForm.current = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.searchForm.current = val
      this.getList()
    },
    async getList() {
      this.dataListLoading = true
      if (this.daterange.length) {
        this.searchForm.replyStartDate = moment(this.daterange[0]).format('YYYY-MM-DD HH:mm:ss')
        this.searchForm.replyEndDate = moment(this.daterange[1]).format('YYYY-MM-DD HH:mm:ss')
      } else {
        this.searchForm.replyStartDate = ''
        this.searchForm.replyEndtDate = ''
      }
      const res = await getDonationRecord({ ...this.searchForm })
      this.dataListLoading = false
      const { success, data } = res
      if (success) {
        this.list = data.records
        this.total = data.total
      }
    },
    onSearch() {
      this.searchForm.current = 1
      this.getList()
    },
    resetSearch(formName) {
      this.searchForm.userName = ''
      this.searchForm.relateProjectName = ''
      this.searchForm.id = ''
      this.daterange = []
      this.$refs[formName].resetFields()
      this.searchForm.current = 1
      this.getList()
    }
  }
}
</script>

<style>

</style>
